import type { DefineImageStyleConfig } from '#rokka/types'
import type { Viewport } from '#rokka/generated-types'
import { rokkaConfig } from '~/nuxtConfig/rokka'

type DefineImageStyleConfigSizesSingle = {
  type: 'sizes-single'
  aspectRatio?: number
  width: number
}
type DefineImageStyleConfigScale = {
  type: 'scale'
  pictures: Partial<
    Record<
      Viewport,
      {
        widthScale: number
        aspectRatio: number
      }
    >
  >
}
const rokkaViewports = rokkaConfig.viewports ?? {}
const lowestRokkaViewport = Object.entries(rokkaViewports).sort(
  ([, v1], [, v2]) => v1 - v2,
)[0][0]
const createImageStyleConfigSizesSingle = (
  config: DefineImageStyleConfigSizesSingle,
) => {
  return defineImageStyle({
    type: 'sizes',
    aspectRatio: config.aspectRatio,
    sizes: {
      [lowestRokkaViewport]: config.width,
    },
  })
}

const nextScreenSize = (viewport: string): number => {
  const rokkaViewportsArray = Object.entries(rokkaViewports)
  const currentIndex = rokkaViewportsArray.findIndex(
    (element) => element[0] === viewport,
  )
  const nextIndex = currentIndex + 1
  if (nextIndex >= rokkaViewportsArray.length) {
    return rokkaViewportsArray[currentIndex][1] * 2
  }

  return rokkaViewportsArray[currentIndex + 1][1]
}
const createImageStyleConfigScale = (config: DefineImageStyleConfigScale) => {
  return defineImageStyle({
    type: 'pictures',
    pictures: Object.fromEntries(
      Object.entries(config.pictures).map(([key, value]) => [
        key,
        {
          width: Math.ceil(value.widthScale * nextScreenSize(key)),
          aspectRatio: value.aspectRatio,
        },
      ]),
    ),
  })
}

export default function (
  config:
    | DefineImageStyleConfig
    | DefineImageStyleConfigSizesSingle
    | DefineImageStyleConfigScale,
) {
  let finalConfig: DefineImageStyleConfig

  if (config.type === 'sizes-single') {
    finalConfig = createImageStyleConfigSizesSingle(config)
  } else if (config.type === 'scale') {
    finalConfig = createImageStyleConfigScale(config)
  } else {
    finalConfig = config
  }

  return defineImageStyle(finalConfig)
}
